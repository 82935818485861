<!-- <div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            
            <div class="w-150 mx-auto">
                <img src="assets/images/logo/logo-eturga.svg">
            </div>

            
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">You have signed out!</div>
            <div class="flex justify-center mt-0.5 font-medium">
               
                <ng-container *ngIf="countdown > 0">
                    Redirecting in {{countdown | i18nPlural: countdownMapping }}
                </ng-container>

                
                <ng-container *ngIf="countdown === 0">
                    You are now being redirected!
                </ng-container>
            </div>

            
            <div class="mt-8 text-md font-medium text-secondary text-center">
                <span>Go to</span>
                <a
                    class="ml-1 text-primary-500 hover:underline"
                    [routerLink]="['/sign-in']">sign in
                </a>
            </div>
        </div>
    </div>
</div> -->
<div class="flex flex-col pt-4 flex-auto mx-auto main-div">
    <h2 mat-dialog-title class=" mr-auto">
        <span>
            {{ "session-expired" | transloco }}
        </span>
    </h2>
    <!-- <mat-dialog-content> -->
    <div>
        {{ "you-will-be-logged-out-due-to-inactivity" | transloco }}
    </div>
    <div class="flex mt-0.5 font-medium">

        <ng-container *ngIf="countdown > 0">
            {{ "redirecting-in" | transloco }} {{countdown | i18nPlural: countdownMapping }}
        </ng-container>


        <ng-container *ngIf="countdown === 0">
            {{ "you-are-now-being-redirected" | transloco }}
        </ng-container>
    </div>
    <!-- </mat-dialog-content> -->
    <mat-dialog-actions class=" flex-auto w-full mt-4 px-2 py-5">
        <div class=" flex-auto">
            <div class="flex justify-end">
                <button (click)="signOut()" class="ml-4" mat-stroked-button type="button">
                    <span>{{ "sign-out" | transloco }}</span>
                </button>
                <button class="ml-4" mat-flat-button type="button" [color]="'primary'" (click)="closeMe()">
                    <span>{{ "stay" | transloco }}</span>
                </button>
            </div>
        </div>

    </mat-dialog-actions>
</div>
<!-- <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                Logo -->
<!-- <div class=" mr-auto">
                   <span>Session expired</span>
                </div> -->

<!-- Title
                <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">You have signed out!</div>
                <div class="flex justify-center mt-0.5 font-medium"> -->
<!-- Redirect countdown -->
<!-- <ng-container *ngIf="countdown > 0">
                        Redirecting in {{countdown | i18nPlural: countdownMapping }}
                    </ng-container> -->

<!-- Redirect message -->
<!-- <ng-container *ngIf="countdown === 0">
                        You are now being redirected!
                    </ng-container>
                </div> -->

<!-- Form footer -->
<!-- <div class=" flex border-t ml-auto   bg-gray-50 dark:bg-gray-700">
                        <div class="mt-8 text-md font-medium text-secondary text-center">
                            <div class="flex items-center justify-end">
                    
                            <button 
                                class="px-6 ml-3"
                                (click)="closeMe()"
                                mat-flat-button
                                color="primary">
                                <span>Stay</span>
                            </button>
                            <button 
                            (click)="signout()"
                                class="px-6 ml-3"
                            
                                mat-flat-button
                                color="warn">
                                <span>Sign Out</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
      -->