/* eslint-disable */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: "dashboard",
        title: "dashboard",
        type: "group",
        subtitle: "Overview and Stats",
        // meta: "simple_user",
        children: [
            // Admin Dashboard
            {
                id: "DASHBOARD",
                title: "dashboard",
                type: "basic",
                meta: "super_admin",
                icon: "heroicons_outline:clipboard-document-check",
                link: "/dashboard"
            }
        ]
    },
    {
        id: "application",
        title: "application",
        type: "group",
        subtitle: "App Management Settings",
        // meta: "simple_user",
        children: [
            {
                id: "LICENSE",
                title: "license",
                type: "basic",
                icon: "feather:award",
                link: "/license",
                meta: "super_admin"
            },
            {
                id: "CONFIGURATIONS",
                title: "configurations",
                type: "basic",
                icon: "feather:settings",
                link: "/configurations",
                meta: "super_admin"
            },
            {
                id: "CONNECTORS",
                title: "connectors",
                type: "basic",
                icon: "heroicons_outline:wrench-screwdriver",
                link: "/connectors",
                meta: "super_admin"
            }
        ]
    },
    {
        id: "customers ",
        title: "customers",
        type: "group",
        subtitle: "Client Relationships Management",
        // meta: "simple_user",
        children: [
            {
                id: "ORGANIZATIONS",
                title: "organization",
                type: "basic",
                icon: "heroicons_outline:building-library",
                link: "/organization",
                meta: "super_admin"
            },
            {
                id: "USERS",
                title: "users",
                type: "basic",
                icon: "heroicons_outline:user-group",
                link: "/users",
                meta: "super_admin"
            }
        ]
    },
    {
        id: "administration",
        title: "administration",
        type: "group",
        subtitle: "Admin Controls",
        // meta: "simple_user",
        children: [
            {
                id: "ADMINISTRATORS",
                title: "administrators",
                type: "basic",
                icon: "heroicons_outline:user-plus",
                link: "/administrators",
                meta: "super_admin"
            },
            {
                id: "ROLES",
                title: "roles",
                type: "basic",
                icon: "heroicons_outline:check-badge",
                link: "/roles",
                meta: "super_admin"
            }
        ]
    },
    {
        id: "finance",
        title: "finance",
        type: "group",
        subtitle: "Financial Operations Management",
        // meta: "simple_user",
        children: [
            {
                id: "PAYMENT_METHODS",
                title: "payment-methods",
                type: "basic",
                icon: "heroicons_outline:banknotes",
                // link: "/organization",
                meta: "super_admin"
            },
            {
                id: "SERVICE_PLANS",
                title: "service-plan",
                type: "basic",
                icon: "feather:package",
                link: "/service-plan",
                meta: "super_admin"
            }
        ]
    },
    {
        id: "audit",
        title: "audit",
        type: "group",
        subtitle: "System Monitoring Logs",
        // meta: "simple_user",
        children: [
            {
                id: "SYSTEM_HEALTH",
                title: "system-health",
                type: "basic",
                icon: "heroicons_outline:rocket-launch",
                // link: "/organization",
                meta: "super_admin"
            },
            {
                id: "ACTIVITY_LOGS",
                title: "activity-logs",
                type: "basic",
                icon: "heroicons_outline:arrow-trending-up",
                // link: '/externalservices',
                meta: "super_admin"
            },
            {
                id: "SYSTEM_LOGS",
                title: "system-logs",
                type: "basic",
                icon: "heroicons_outline:chart-pie",
                // link: "/serviceplan",
                meta: "super_admin"
            }
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: "example",
        title: "Example",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/example"
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: "example",
        title: "Example",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/example"
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: "example",
        title: "Example",
        type: "basic",
        icon: "heroicons_outline:chart-pie",
        link: "/example"
    }
];
