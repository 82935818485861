<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user.avatar"
            [src]="user.avatar"
        />
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"
        ></mat-icon>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <!-- <span>Signed in as</span> -->
            <span>{{ "signed-in-as" | transloco }}</span>

            <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
        </span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon
            [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
        ></mat-icon>
        <span>{{ "sign-out" | transloco }}</span>
    </button>
</mat-menu>

