import { Inject, Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { AuthService } from "app/core/auth/auth.service";
import { Observable, fromEvent, merge, Subject, timer, Subscription } from "rxjs";
import { IdleComponent } from "./idle.component";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  dialogForm: FormGroup;
  constructor(private idlecomponent: MatDialog, private _authService: AuthService, private _formBuilder: FormBuilder, private service: AuthService, private _router: Router, private _fuseConfirmationService: FuseConfirmationService) {}

  private idle: Observable<any> = new Observable();
  private timer: Subscription = new Subscription();
  private timeOutMilliSeconds: number = 1000;
  private idleSubscription: Subscription = new Subscription();
  public expired: Subject<boolean> = new Subject<boolean>();

  public startWatching(timeOutSeconds: number): Observable<any> {
    this.idle = merge(fromEvent(document, "mousemove"), fromEvent(document, "click"), fromEvent(document, "mousedown"), fromEvent(document, "keypress"), fromEvent(document, "DOMMouseScroll"), fromEvent(document, "mousewheel"), fromEvent(document, "touchmove"), fromEvent(document, "MSPointerMove"), fromEvent(window, "mousemove"), fromEvent(window, "resize"));
    this.timeOutMilliSeconds = timeOutSeconds * 1000;
    this.idleSubscription = this.idle.subscribe((res) => {
      this.resetTimer();
    });
    this.startTimer();
    return this.expired;
  }
  private startTimer() {
    this.timer = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe((res) => {
      this.expired.next(true);
      // console.log("event is trigering start time");
      //  this._router.navigate(['/sign-out'] );
    });
  }
  public resetTimer() {
    this.timer.unsubscribe();
    this.startTimer();
    // console.log("event is trigering reset");
  }
  public stopTimer() {
    this.timer.unsubscribe();
    this.idleSubscription.unsubscribe();
    // console.log("event is trigiring stop");
  }

  //   openConformation(data) {

  //     const dialogConfig = new MatDialogConfig();
  //     dialogConfig.data = {

  //     }
  //       let dialogRef = this.securityDialog.open(IdleComponent, dialogConfig);
  //       dialogRef.afterClosed().subscribe((result) => {

  //       });

  // }
  openConformation(data) {
    let dialogRef = this.idlecomponent.open(IdleComponent);
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
    });
  }

  openTimeOutDialogForm(status, message) {
    this.dialogForm = this._formBuilder.group({
      title: status === "error" ? "Error" : "Session expired.",
      message: '<span class="font-medium">' + message + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: "Document list",
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          label: "Stay",
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "primary",
          label: "Sign Out",
          // label: "Document list",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "confirmed") {
        // this._router.navigate(['/sign-out']);
        let redirectURL = "/sign-out";
        this._router.navigateByUrl(redirectURL);
        this.expired;
      }
    });
  }

  openDialogForm(status, message): void {
    this.dialogForm = this._formBuilder.group({
      title: status === "error" ? "Error" : "Session expired.",
      message: '<span class="font-medium">' + message + "</span>",
      icon: this._formBuilder.group({
        show: true,
        name: status === "error" ? "heroicons_outline:badge-check" : "heroicons_outline:exclamation",
        color: status === "error" ? "warn" : "success",
      }),
      actions: this._formBuilder.group({
        show: true,
        label: "Document list",
        color: status === "error" ? "warn" : "success",

        cancel: this._formBuilder.group({
          show: true,
          color: "primary",
          label: "Stay",
        }),
        confirm: this._formBuilder.group({
          show: true,
          color: "warn",
          label: "Sign Out",
        }),
      }),
      // dismissible: true
    });

    const dialogRef = this._fuseConfirmationService.open(this.dialogForm.value);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "confirmed") {
        // this._router.navigate(['/sign-out']);
        let redirectURL = "/sign-out";
        this._router.navigateByUrl(redirectURL);
        this.expired;
      }
    });
  }
}
