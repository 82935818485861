import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { appConfig } from '../config/app.config.dev';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    private permissionsSubject: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
    permissions$: Observable<string[]> = this.permissionsSubject.asObservable();

    private apiUrl = appConfig.basePath + 'admin/v1/administrators/permission/modules';

    constructor(private http: HttpClient) { }

    fetchPermissions(): Observable<string[]> {
        return this.http.get<{ moduleNames: string[] }>(this.apiUrl).pipe(
            switchMap(response => {
                this.permissionsSubject.next(response.moduleNames);
                return of(response.moduleNames);
            }),
            catchError(error => {
                console.error('Error fetching permissions', error);
                return of([]); // Return an empty array on error
            })
        );
    }

    getPermissions(): Observable<string[]> {
        return this.permissions$.pipe(
            switchMap(permissions => {
                if (permissions === null) {
                    return this.fetchPermissions();
                }
                return of(permissions);
            })
        );
    }

    removePermissions(): void {
        this.permissionsSubject.next(null);
    }
}
