import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Observable, of, switchMap } from 'rxjs';




@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        let redirectUrl = state.url === "/sign-out" ? "/" : state.url;

        // const userRole = localStorage.getItem("userRole");
        // const name: string = "/superAdminSettings";

        // if (userRole === "ROLE_SUPER_ADMIN") {
        //     if (redirectUrl.startsWith(name)) {
        //         //console.log(redirectUrl, "Inside true flag");
        //         return this._check(redirectUrl);
        //     } else {
        //         // console.log(redirectUrl, "Inside false flag");
        //         return false;
        //     }
        // } else if (userRole === "ROLE_ADMIN") {
        //     if (redirectUrl.startsWith(name.substring(0, 3))) {
        //         //  console.log(redirectUrl, "Inside false flag");
        //         return false;
        //     } else {
        //         return this._check(redirectUrl);
        //     }
        // } else {
        // console.log(redirectUrl, "Inside false flag 2");
        return this._check(redirectUrl);
        // }

    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check("/");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean> {
        // Check the authentication status
        return this._authService.check(redirectURL).pipe(
          switchMap((authenticated) => {

            // If the user is not authenticated...
            if (!authenticated) {
              // console.log("here in authService.check()");
    
              localStorage.setItem(
                "accessToken",
                "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjQ4NDY0ODk2LCJleHAiOjE2NDg0ODY0OTZ9.201mCFEoEcnzPqWo5nlPF_T9ZgSntjiSX5LYXtd04Og"
              );
              // Redirect to the sign-in page
              this._router.navigate(["sign-in"], { queryParams: { redirectURL } });
    
              // Prevent the access
              return of(false);
            }
    
            // Allow the access
            return of(true);
          })
        );
      }
}
