import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguagesComponent } from './layout/common/languages/languages.component';


@Component({
    selector: 'signout',
    templateUrl: './idle.component.html',
    styleUrls: ['./idle.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        RouterLink,
        FuseAlertComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        TranslocoModule,
        LanguagesComponent,
        MatDialogActions
    ],
})
export class IdleComponent implements OnInit {
    countdown: number = 5;
    counting: number = 1000
    countdownMapping: any = {
        '=1': '# second',
        'other': '# seconds'
    };
    private idleSubscription: Subscription = new Subscription();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private dialogRef: MatDialogRef<IdleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    idleFunction() {
        timer(this.counting, this.counting)
            .pipe(
                finalize(() => {
                    this.dialogRef.close();
                    // console.log("your component is idle");

                    this._authService.signOut();
                    this._router.navigate(['sign-in']);
                    window.location.reload()
                }),
                takeWhile(() => this.countdown > 0),
                takeUntil(this._unsubscribeAll),
                tap(() => this.countdown--)
            )
            .subscribe((result) => {
                // console.log("result");
                this._unsubscribeAll
                // console.log(result);

            });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.idleFunction()
        // Sign out
        // this._authService.signOut();
        // Redirect after the countdown


    }
    signOut(): void {
        this.dialogRef.close();
        this._router.navigate(['/sign-out']);
    }
    public closeMe() {
        window.location.reload();
        // this.dialogRef.close();
    }


    /**
     * On destroy
     */
}
