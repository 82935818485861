import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { appConfig } from "../config/app.config.dev";

interface AccessRights {
    canView: boolean;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
}

interface Permission {
    moduleName: string;
    accessRights: AccessRights;
}

interface Role {
    name: string;
    description: string;
    status: string;
    permissions: Permission[];
}

@Injectable({
    providedIn: "root"
})
export class AdminService {
    constructor(private _httpClient: HttpClient) { }

    getAdminDetails(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/dashboard/profile");
    }

    // ****************************** Admin DashBoard **************************** //

    getMain(): Observable<any> {
        return this._httpClient.get<any>(appConfig.basePath + "admin/v1/dashboard/dashboardStatus");
    }

    // ************************ License Management ************************ //

    /**
     * @param formData
     */
    uploadLicense(fileToUpload: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append("licenseFile", fileToUpload, fileToUpload.name);
        return this._httpClient.post(appConfig.basePath + "admin/v1/license/upload", formData);
    }

    // ************************ Configuration Management ************************ //
    updateApplicationSettings(
        applicationName: any,
        companyName: any,
        adminUri: any,
        applicationUri: any
    ): Observable<any> {
        const body = {
            applicationName: applicationName,
            companyName: companyName,
            adminUri: adminUri,
            applicationUri: applicationUri
        };
        return this._httpClient.put(appConfig.basePath + "admin/v1/settings/application", body);
    }

    getApplicationSettings(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/application");
    }

    updateAuthenticationSettings(settings: {
        accessTokenLife: number;
        refreshTokenLife: number;
        guestUserTokenLife: number;
        enableMfa: boolean;
        enableMfaViaSms: boolean;
        enableMfaViaAuthenticatorApp: boolean;
        tokenSecret: any;
        enableGoogleAuthentication: boolean;
    }): Observable<any> {
        return this._httpClient.put(appConfig.basePath + "admin/v1/settings/authentication", settings);
    }
    getAuthenticationSettings(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/authentication");
    }

    updateStorageSettings(settings: {
        documentStorage: string;
        fileStorageUrl: string;
        evidenceReportUrl: string;
        completedDocumentsUrl: string;
    }): Observable<any> {
        return this._httpClient.put(appConfig.basePath + "admin/v1/settings/storageSettings", settings);
    }
    getStorageSettings(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/storageSettings");
    }

    updateSecuritySettings(settings: { iv: any; key: any; algorithm: any }): Observable<any> {
        return this._httpClient.put(appConfig.basePath + "admin/v1/settings/hashEncryption", settings);
    }
    getSecuritySettings(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/hashEncryption");
    }

    updateSignatureSettings(settings: {
        enableDigitalSignatures: boolean;
        enableElectronicSignatures: boolean;
        enableSealingSignatures: boolean;
        generateEvidenceReport: boolean;
        sealingCertificate: any;
        sealingCertificatePassword: any;
        evidenceSealingCertificate: any;
        evidenceSealingPassword: any;
    }): Observable<any> {
        return this._httpClient.put(appConfig.basePath + "admin/v1/settings/signatureSettings", settings);
    }
    getSignatureSettings(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/signatureSettings");
    }

    // ************************ External Services ************************ //

    updateSigningMiddleware(obj: { name: any; description: any; serverUrl: any }): Observable<any> {
        return this._httpClient.put(`${appConfig.basePath}admin/v1/settings/external/signingMiddleware`, obj);
    }
    getSigningMiddleware(): Observable<any> {
        return this._httpClient.get(`${appConfig.basePath}admin/v1/settings/external/signingMiddleware`);
    }

    updateSnsSettings(obj: { name: any; description: any; apiKey: any; apiSecret: any }): Observable<any> {
        return this._httpClient.post(appConfig.basePath + "admin/v1/settings/external/sns", obj);
    }
    getSnsSettings(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/external/sns");
    }

    updateTwilioSettings(obj: { name: any; description: any; apiKey: any; apiSecret: any }): Observable<any> {
        return this._httpClient.put(appConfig.basePath + "admin/v1/settings/external/twilio", obj);
    }
    getTwilioSettings(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/external/twilio");
    }

    updateAmazonServer(obj: {
        name: any;
        description: any;
        apiKey: any;
        apiSecret: any;
        region: any;
    }): Observable<any> {
        return this._httpClient.put(appConfig.basePath + "admin/v1/settings/external/amazon", obj);
    }
    getAmazonServer(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/settings/external/amazon");
    }

    updateSmtpServer(obj: {
        name: any;
        description: any;
        smtpUrl: any;
        port: number;
        ssl: boolean;
        authentication: boolean;
        username: any;
        password: any;
        email: any;
    }): Observable<any> {
        return this._httpClient.put(`${appConfig.basePath}admin/v1/settings/external/smtp`, obj);
    }
    getSmtpServer(): Observable<any> {
        return this._httpClient.get(`${appConfig.basePath}admin/v1/settings/external/smtp`);
    }

    updateClientApp(obj: { name: any; description: any; clientAppUrl: any }): Observable<any> {
        return this._httpClient.put(`${appConfig.basePath}admin/v1/settings/external/clientApp`, obj);
    }
    getClientApp(): Observable<any> {
        return this._httpClient.get(`${appConfig.basePath}admin/v1/settings/external/clientApp`);
    }

    updateCscServer(obj: { name: any; description: any; serverUrl: any; clientId: any }): Observable<any> {
        return this._httpClient.put(`${appConfig.basePath}admin/v1/settings/external/cscServer`, obj);
    }
    getCscServer(): Observable<any> {
        return this._httpClient.get(`${appConfig.basePath}admin/v1/settings/external/cscServer`);
    }

    // getLicenses(page: string,size: string): Observable<any> {
    //     return this._httpClient.get<any>(appConfig.basePath + "admin/v1/license?page="+page+"&size="+size);
    // }

    getLicenses(page: string, size: string): Observable<any> {
        const url = `${appConfig.basePath}admin/v1/license?page=${page}&size=${size}`;
        return this._httpClient.get<any>(url);
    }

    getCurrentLicense(includeDetails: boolean = true): Observable<any> {
        const params = new HttpParams().set("details", includeDetails.toString());
        return this._httpClient.get(appConfig.basePath + "admin/v1/license/currentLicense", { params });
    }

    // *************************************** Users ***************************************** //

    getUsersList(
        name: string,
        email: string,
        status: string,
        orgName: string,
        searchValue: string,
        pageNo: any,
        pageSize: any,
        sort: any,
        order: any
    ): Observable<any> {
        let basePath = appConfig.basePath + "admin/v1/users?page=" + pageNo + "&size=" + pageSize;
        if (name != "") {
            basePath = basePath + "&name=" + name;
        }
        if (email != "") {
            basePath = basePath + "&email=" + email;
        }
        if (orgName != "") {
            basePath = basePath + "&orgName=" + orgName;
        }
        if (status != "") {
            basePath = basePath + "&status=" + status;
        }
        if (sort != "") {
            basePath = basePath + "&sort=" + sort;
        }
        if (order != "") {
            basePath = basePath + "," + order;
        }
        if (searchValue != "") {
            basePath = basePath + "&searchValue=" + searchValue;
        }

        return this._httpClient.get(basePath);
    }

    deleteUser(id: any): Observable<any> {
        return this._httpClient.delete(appConfig.basePath + "admin/v1/users/" + id);
    }

    deleteOrganization(id: any): Observable<any> {
        return this._httpClient.delete(appConfig.basePath + "admin/v1/organizations/" + id);
    }

    getTopUsers(): Observable<any> {
        return this._httpClient.get<any>(appConfig.basePath + "admin/v1/users/top4");
    }

    updateUserStatus(id: any, status: any): Observable<any> {
        return this._httpClient.put(appConfig.basePath + "admin/v1/users/status/" + id, { status });
    }

    updateUserPassword(id: any): Observable<any> {
        return this._httpClient.post(appConfig.basePath + "admin/v1/users/password/" + id, null);
    }

    // *************************************** Organization ***************************************** //

    getOrganizationsList(
        name: string,
        ownerEmail: string,
        searchValue: string,
        pageNo: any,
        pageSize: any,
        sort: any,
        order: any
    ): Observable<any> {
        let basePath = appConfig.basePath + "admin/v1/organizations?page=" + pageNo + "&size=" + pageSize;
        if (name != "") {
            basePath = basePath + "&name=" + name;
        }
        if (ownerEmail != "") {
            basePath = basePath + "&email=" + ownerEmail;
        }
        if (sort != "") {
            basePath = basePath + "&sort=" + sort;
        }
        if (order != "") {
            basePath = basePath + "," + order;
        }
        if (searchValue != "") {
            basePath = basePath + "&searchValue=" + searchValue;
        }

        return this._httpClient.get(basePath);
    }

    getTopOrganizations(): Observable<any> {
        return this._httpClient.get<any>(appConfig.basePath + "admin/v1/organizations/topFourOrg");
    }

    // *************************************** Get Connectors ***************************************** //

    getConnectors(
        searchValue: string,
        name: string,
        pageNo: any,
        pageSize: any,
        sort: any,
        order: any
    ): Observable<any> {
        let basePath = appConfig.basePath + "admin/v1/connectors?page=" + pageNo + "&size=" + pageSize;

        if (sort != "") {
            basePath = basePath + "&sort=" + sort;
        }
        if (order != "") {
            basePath = basePath + "," + order;
        }
        if (searchValue != "") {
            basePath = basePath + "&searchValue=" + searchValue;
        }
        if (name != "") {
            basePath = basePath + "&name=" + name;
        }

        return this._httpClient.get(basePath);
    }

    // *************************************** Add Connectors ***************************************** //

    addConnector(name: string, description: string, status: string, purpose: any, provider: any): Observable<any> {
        return this._httpClient.post(appConfig.basePath + "admin/v1/connectors", {
            name,
            description,
            status,
            purpose,
            provider
        });
    }

    // *************************************** Delete Connectors ***************************************** //

    deleteConnector(connectorID: number): Observable<any> {
        return this._httpClient.delete(appConfig.basePath + `admin/v1/connectors/${connectorID}`);
    }

    // *************************************** Update Connectors ***************************************** //

    updateConnector(id: any, name: string, description: any, status: any): Observable<any> {
        return this._httpClient.put(appConfig.basePath + `admin/v1/connectors`, {
            id,
            name,
            description,
            status
        });
    }

    // *************************************** Update smtp configuration ***************************************** //
    updateConnectorConfig(
        connectorID: Number,
        smtpServer: string,
        port: string,
        sslTls: boolean,
        authentication: boolean,
        from: string,
        userId: string,
        password: string
    ): Observable<any> {
        return this._httpClient.put(appConfig.basePath + `admin/v1/connectors-config`, {
            connectorID,
            configurations: {
                SMTP_SERVER: smtpServer,
                SMTP_PORT: port,
                SSL_TLS: sslTls,
                AUTHENTICATION: authentication,
                USER_ID: userId,
                PASSWORD: password,
                EMAIL_FROM: from
            }
        });
    }

    // *************************************** Get configuration ***************************************** //
    getConnectorConfig(connectorID: Number): Observable<any> {
        const params = new HttpParams().set("connectorID", connectorID.toString());
        return this._httpClient.get(appConfig.basePath + `admin/v1/connectors-config`, { params });
    }

    // *************************************** Update SendGrid configuration ***************************************** //
    updateConnectorConfigSendGrid(connectorID: Number, apiKey: string, from: string): Observable<any> {
        return this._httpClient.put(appConfig.basePath + `admin/v1/connectors-config`, {
            connectorID,
            configurations: {
                API_KEY: apiKey,
                EMAIL_FROM: from
            }
        });
    }

    // *************************************** Update Amazon SES configuration ***************************************** //
    updateConnectorConfigAmazonSes(
        connectorID: Number,
        apiKey: string,
        accessKey: string,
        region: string,
        from: string
    ): Observable<any> {
        return this._httpClient.put(appConfig.basePath + `admin/v1/connectors-config`, {
            connectorID,
            configurations: {
                API_KEY: apiKey,
                ACCESS_KEY: accessKey,
                REGION: region,
                EMAIL_FROM: from
            }
        });
    }

    // *************************************** Update Etugtra Middleware configuration ***************************************** //
    updateConnectorConfigEtugraMiddleware(
        connectorID: Number,
        serverUrl: string,
        aes: boolean,
        qes: boolean
    ): Observable<any> {
        return this._httpClient.put(appConfig.basePath + `admin/v1/connectors-config`, {
            connectorID,
            configurations: {
                SERVER_URL: serverUrl,
                AES: aes,
                QES: qes
            }
        });
    }

    // *************************************** Update Etugra Signer App configuration ***************************************** //
    updateConnectorConfigEtugraServiceApp(
        connectorID: Number,
        appUrl: string,
        aes: boolean,
        qes: boolean
    ): Observable<any> {
        return this._httpClient.put(appConfig.basePath + `admin/v1/connectors-config`, {
            connectorID,
            configurations: {
                APP_URL: appUrl,
                AES: aes,
                QES: qes
            }
        });
    }

    // *************************************** Roles ***************************************** //

    getRolesList(
        name: string,
        description: string,
        status: string,
        searchValue: string,
        pageNo: any,
        pageSize: any,
        sort: any,
        order: any
    ): Observable<any> {
        let basePath = appConfig.basePath + "admin/v1/roles?page=" + pageNo + "&size=" + pageSize;
        if (name != "") {
            basePath = basePath + "&name=" + name;
        }
        if (description != "") {
            basePath = basePath + "&description=" + description;
        }
        if (status != "") {
            basePath = basePath + "&status=" + status;
        }
        if (sort != "") {
            basePath = basePath + "&sort=" + sort;
        }
        if (order != "") {
            basePath = basePath + "," + order;
        }
        if (searchValue != "") {
            basePath = basePath + "&searchValue=" + searchValue;
        }

        return this._httpClient.get(basePath);
    }

    addRole(roleRequest: Role): Observable<any> {
        return this._httpClient.post(appConfig.basePath + "admin/v1/roles", roleRequest);
    }

    updateRoleDetails(id: number, description: string, status: string): Observable<any> {
        const basePath = appConfig.basePath + "admin/v1/roles/" + id;

        return this._httpClient.put(basePath, { description, status });
    }

    updateRolePermission(id: number, permissions: Permission[]): Observable<any> {
        const basePath = appConfig.basePath + "admin/v1/roles/" + id + "/permissions";

        return this._httpClient.put(basePath, { permissions });
    }

    deleteRole(roleIds: any[]): Observable<any> {
        const params = new HttpParams().set('roleIds', roleIds.join(','));
        const url = `${appConfig.basePath}admin/v1/roles`;

        return this._httpClient.delete(url, {
            params: params,
            responseType: 'json'
        });
    }

    getRole(roleId: any): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/roles/" + roleId);
    }

    getAvailableRoles(): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/roles/available");
    }

    // *************************************** Administrators ***************************************** //

    getAdministratorsList(
        name: string,
        email: string,
        status: string,
        roleName: string,
        searchValue: string,
        pageNo: any,
        pageSize: any,
        sort: any,
        order: any
    ): Observable<any> {
        let basePath = appConfig.basePath + "admin/v1/administrators?page=" + pageNo + "&size=" + pageSize;
        if (name != "") {
            basePath = basePath + "&name=" + name;
        }
        if (email != "") {
            basePath = basePath + "&email=" + email;
        }
        if (status != "") {
            basePath = basePath + "&status=" + status;
        }
        if (roleName != "") {
            basePath = basePath + "&roleName=" + roleName;
        }
        if (sort != "") {
            basePath = basePath + "&sort=" + sort;
        }
        if (order != "") {
            basePath = basePath + "," + order;
        }
        if (searchValue != "") {
            basePath = basePath + "&searchValue=" + searchValue;
        }

        return this._httpClient.get(basePath);
    }

    addAdmin(name: string, emailAddress: string, roleId: number, status: string, password: string): Observable<any> {
        return this._httpClient.post(appConfig.basePath + "admin/v1/administrators", {
            name,
            emailAddress,
            roleId,
            status,
            password
        });
    }

    updateAdmin(id: number, name: string, roleId: number, status: string, password: string): Observable<any> {
        if (password) {
            return this._httpClient.put(appConfig.basePath + "admin/v1/administrators/" + id, {
                name,
                roleId,
                status,
                password
            });
        } else {
            return this._httpClient.put(appConfig.basePath + "admin/v1/administrators/" + id, { name, roleId, status });
        }
    }

    deleteAdmin(id: number): Observable<any> {
        return this._httpClient.delete(appConfig.basePath + "admin/v1/administrators/" + id);
    }

    getAdminById(id: number): Observable<any> {
        return this._httpClient.get(appConfig.basePath + "admin/v1/administrators/" + id);
    }

    // *************************************** Service Plan Management ***************************************** //

    getPlans(
        name: string,
        description: string,
        search: string,
        pageNo: any,
        pageSize: any,
        sort: any,
        order: any
    ): Observable<any> {
        let basePath = appConfig.basePath + 'admin/v1/service-plan?page=' + pageNo + '&size=' + pageSize;
        if (name != "") {
            basePath = basePath + "&name=" + name;
        }
        if (description != "") {
            basePath = basePath + "&description=" + description;
        }
        if (search != "") {
            basePath = basePath + "&search=" + search;
        }
        if (sort != "") {
            basePath = basePath + "&sort=" + sort;
        }
        if (order != "") {
            basePath = basePath + "," + order;
        }

        return this._httpClient.get(basePath);
    }

    addPlan(
        name: string,
        description: string,
        templates: number,
        signatures: number,
        ses: boolean,
        aes: boolean,
        qes: boolean,
        mfa: boolean,
        status: string,
        preset: boolean
    ): Observable<any> {
        return this._httpClient.post(appConfig.basePath + 'admin/v1/service-plan', {
            name,
            description,
            templates,
            signatures,
            ses,
            aes,
            qes,
            mfa,
            status,
            preset
        })
    }

    getPlanById(id: string): Observable<any> {
        return this._httpClient.get(appConfig.basePath + 'admin/v1/service-plan/' + id);
    }

    updatePlan(
        id: string,
        name: string,
        description: string,
        templates: number,
        signatures: number,
        ses: boolean,
        aes: boolean,
        qes: boolean,
        mfa: boolean,
        status: string,
        preset: boolean
    ): Observable<any> {
        return this._httpClient.put(appConfig.basePath + 'admin/v1/service-plan', {
            id,
            name,
            description,
            templates,
            signatures,
            ses,
            aes,
            qes,
            mfa,
            status,
            preset
        })
    }

    deletePlan(id: string): Observable<any> {
        return this._httpClient.delete(appConfig.basePath + 'admin/v1/service-plan/' + id);
    }

    updateDefault(id: string): Observable<any> {
        return this._httpClient.get(appConfig.basePath + 'admin/v1/service-plan/default/' + id);
    }
}
