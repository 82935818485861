import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { PermissionsService } from 'app/core/permissionsService/permissions.service';
import {
    compactNavigation,
    defaultNavigation,
    futuristicNavigation,
    horizontalNavigation,
} from 'app/mock-api/common/navigation/data';
import { cloneDeep } from 'lodash-es';
import { catchError, map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {
    private readonly _compactNavigation: FuseNavigationItem[] =
        compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] =
        defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] =
        futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] =
        horizontalNavigation;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService, private _permissionsService: PermissionsService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            // Fill compact navigation children using the default navigation
            return this._permissionsService.getPermissions().pipe(
                map(allowedIds => {
                    // Filter the navigation based on allowed IDs
                    const filterNavigation = (navigation: FuseNavigationItem[]): FuseNavigationItem[] => {
                        return navigation
                            .map(item => ({
                                ...item,
                                children: item.children ? filterNavigation(item.children) : []
                            }))
                            .filter(item => item.children.length > 0 || allowedIds.includes(item.id));
                    };

                    const filteredDefaultNavigation = filterNavigation(cloneDeep(this._defaultNavigation));
                    const filteredCompactNavigation = filterNavigation(cloneDeep(this._compactNavigation));
                    const filteredFuturisticNavigation = filterNavigation(cloneDeep(this._futuristicNavigation));
                    const filteredHorizontalNavigation = filterNavigation(cloneDeep(this._horizontalNavigation));

                    return [
                        200,
                        {
                            compact: filteredCompactNavigation,
                            default: filteredDefaultNavigation,
                            futuristic: filteredFuturisticNavigation,
                            horizontal: filteredHorizontalNavigation,
                        },
                    ];
                }),
                catchError(error => {
                    console.error('Error filtering navigation', error);
                    return [500, { error: 'Failed to filter navigation' }];
                })
            );
        });
    }
}
