<div
    class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-96 lg:w-16 lg:h-screen lg:shadow"
>
    <div
        class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{
            '-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow':
                opened,
            'translate-x-0': !opened
        }"
    >
        <!-- Header -->
        <div
            class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer"
            (click)="toggle()"
        >
            <!-- Toggle -->
            <ng-container *ngIf="!opened || (opened && !selectedChat)">
                <div class="flex flex-auto items-center justify-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            [svgIcon]="
                                'heroicons_outline:chat-bubble-left-right'
                            "
                        ></mat-icon>
                    </div>
                    <div class="text-lg font-medium text-secondary">
                        Team Chat
                    </div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon
                            [svgIcon]="'heroicons_outline:x-mark'"
                        ></mat-icon>
                    </button>
                </div>
            </ng-container>

            <!-- Contact info -->
            <ng-container *ngIf="opened && selectedChat">
                <div class="flex flex-auto items-center ml-3">
                    <div
                        class="relative flex flex-0 items-center justify-center w-10 h-10"
                    >
                        <ng-container *ngIf="chat.contact.avatar">
                            <img
                                class="w-full h-full rounded-full object-cover"
                                [src]="chat.contact.avatar"
                                alt="Contact avatar"
                            />
                        </ng-container>
                        <ng-container *ngIf="!chat.contact.avatar">
                            <div
                                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                            >
                                {{ chat.contact.name.charAt(0) }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="ml-4 text-lg font-medium leading-5 truncate">
                        {{ chat.contact.name }}
                    </div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon
                            [svgIcon]="'heroicons_outline:x-mark'"
                        ></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <!-- Content -->
        <div class="flex flex-auto border-t overflow-hidden">
            <!-- Chat list -->
            <div
                class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
                fuseScrollbar
                [fuseScrollbarOptions]="{ wheelPropagation: false }"
            >
                <div class="flex-auto">
                    <ng-container
                        *ngFor="let chat of chats; trackBy: trackByFn"
                    >
                        <div
                            class="flex items-center py-3 px-4 cursor-pointer"
                            [ngClass]="{
                                'hover:bg-gray-100 dark:hover:bg-hover':
                                    !selectedChat ||
                                    selectedChat.id !== chat.id,
                                'bg-primary-50 dark:bg-hover':
                                    selectedChat && selectedChat.id === chat.id
                            }"
                            (click)="selectChat(chat.id)"
                        >
                            <div
                                class="relative flex flex-0 items-center justify-center w-8 h-8"
                            >
                                <ng-container *ngIf="chat.unreadCount > 0">
                                    <div
                                        class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                        [class.ring-primary-50]="
                                            selectedChat &&
                                            selectedChat.id === chat.id
                                        "
                                    ></div>
                                </ng-container>
                                <ng-container *ngIf="chat.contact.avatar">
                                    <img
                                        class="w-full h-full rounded-full object-cover"
                                        [src]="chat.contact.avatar"
                                        alt="Contact avatar"
                                    />
                                </ng-container>
                                <ng-container *ngIf="!chat.contact.avatar">
                                    <div
                                        class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                                    >
                                        {{ chat.contact.name.charAt(0) }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Conversation -->
            <div
                class="flex flex-col flex-auto border-l overflow-hidden bg-gray-50 dark:bg-transparent"
            >
                <ng-container *ngIf="chat; else selectChatOrStartNew">
                    <div
                        class="flex flex-col-reverse overflow-y-auto overscroll-y-contain"
                    >
                        <div class="flex flex-col flex-auto shrink p-6">
                            <ng-container
                                *ngFor="
                                    let message of chat.messages;
                                    let i = index;
                                    let first = first;
                                    let last = last;
                                    trackBy: trackByFn
                                "
                            >
                                <!-- Start of the day -->
                                <ng-container
                                    *ngIf="
                                        first ||
                                        (chat.messages[i - 1].createdAt
                                            | date: 'd') !==
                                            (message.createdAt | date: 'd')
                                    "
                                >
                                    <div
                                        class="flex items-center justify-center my-3 -mx-6"
                                    >
                                        <div class="flex-auto border-b"></div>
                                        <div
                                            class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary"
                                        >
                                            {{
                                                message.createdAt
                                                    | date: "longDate"
                                            }}
                                        </div>
                                        <div class="flex-auto border-b"></div>
                                    </div>
                                </ng-container>
                                <div
                                    class="flex flex-col"
                                    [ngClass]="{
                                        'items-end': message.isMine,
                                        'items-start': !message.isMine,
                                        'mt-0.5':
                                            i > 0 &&
                                            chat.messages[i - 1].isMine ===
                                                message.isMine,
                                        'mt-3':
                                            i > 0 &&
                                            chat.messages[i - 1].isMine !==
                                                message.isMine
                                    }"
                                >
                                    <!-- Bubble -->
                                    <div
                                        class="relative max-w-3/4 px-3 py-2 rounded-lg"
                                        [ngClass]="{
                                            'bg-blue-500 text-blue-50':
                                                message.isMine,
                                            'bg-gray-500 text-gray-50':
                                                !message.isMine
                                        }"
                                    >
                                        <!-- Speech bubble tail -->
                                        <ng-container
                                            *ngIf="
                                                last ||
                                                chat.messages[i + 1].isMine !==
                                                    message.isMine
                                            "
                                        >
                                            <div
                                                class="absolute bottom-0 w-3"
                                                [ngClass]="{
                                                    'text-blue-500 -right-1 -mr-px mb-px':
                                                        message.isMine,
                                                    'text-gray-500 -left-1 -ml-px mb-px -scale-x-1':
                                                        !message.isMine
                                                }"
                                            >
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        speechBubbleExtension
                                                    "
                                                ></ng-container>
                                            </div>
                                        </ng-container>
                                        <!-- Message -->
                                        <div
                                            class="min-w-4 leading-5"
                                            [innerHTML]="message.value"
                                        ></div>
                                    </div>
                                    <!-- Time -->
                                    <ng-container
                                        *ngIf="
                                            first ||
                                            last ||
                                            chat.messages[i + 1].isMine !==
                                                message.isMine ||
                                            chat.messages[i + 1].createdAt !==
                                                message.createdAt
                                        "
                                    >
                                        <div
                                            class="my-0.5 text-sm font-medium text-secondary"
                                            [ngClass]="{
                                                'mr-3': message.isMine,
                                                'ml-3': !message.isMine
                                            }"
                                        >
                                            {{
                                                message.createdAt
                                                    | date: "HH:mm"
                                            }}
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!-- Message field -->
                    <div
                        class="flex items-end p-4 border-t bg-gray-50 dark:bg-transparent"
                    >
                        <mat-form-field
                            class="fuse-mat-dense fuse-mat-rounded fuse-mat-bold w-full"
                            [subscriptSizing]="'dynamic'"
                        >
                            <textarea
                                matInput
                                cdkTextareaAutosize
                                #messageInput
                            ></textarea>
                        </mat-form-field>
                        <div class="flex items-center h-11 my-px ml-4">
                            <button mat-icon-button>
                                <mat-icon
                                    [svgIcon]="
                                        'heroicons_outline:paper-airplane'
                                    "
                                ></mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Select chat or start new template -->
<ng-template #selectChatOrStartNew>
    <div
        class="flex flex-col flex-auto items-center justify-center w-full h-full p-4"
    >
        <mat-icon
            class="icon-size-24"
            [svgIcon]="'heroicons_outline:chat-bubble-bottom-center-text'"
        ></mat-icon>
        <div
            class="mt-4 text-xl text-center font-medium tracking-tight text-secondary"
        >
            Select a conversation
        </div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <path
                d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                fill="currentColor"
                fill-rule="nonzero"
            ></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->
